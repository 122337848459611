<template>
  <div v-if="isAuthenticated && isSage">
    <div class="grid-x grid-padding-x">
      <div class="cell callout success"><h3><i class="fi-price-tag"></i>&nbsp;&nbsp;&nbsp;Test-Lizenz-Anfragen
      </h3></div>
    </div>
    <div class="grid-x grid-padding-x grid-padding-y">

      <div class="cell">
        <md-table v-model="searched" md-sort="organizationName" md-sort-order="asc" md-card md-fixed-header
                  @md-selected="onSelect" :style="tableStyle">
          <md-table-toolbar>
            <div class="md-toolbar-section-start grid-x">
              <div class="cell large-8 medium-8 small-6">
                <p class="md-title ai-number">{{searched.length}}&nbsp;Test-Lizenz-Anfragen</p>
              </div>
              <div class="cell large-4 medium-4 small-6">
                <div v-if="sending">
                  <vue-simple-spinner></vue-simple-spinner>
                </div>
              </div>
            </div>
            <md-field md-clearable class="md-toolbar-section-end">
              <md-input placeholder="Suche Email ..." v-model="search" @input="searchOnTable"/>
            </md-field>
          </md-table-toolbar>

          <md-table-empty-state v-if="search"
                                md-label="Keine Anfrage gefunden"
                                :md-description="`Keine Anfrage mit der Email '${search}' gefunden. Versuchen Sie es mit einer anderen Email.`">
          </md-table-empty-state>

          <md-table-row :id="item.id" slot="md-table-row" slot-scope="{ item }" :class="getClass(item)" md-selectable="single">
            <md-table-cell md-label="Organization" md-sort-by="organizationName">{{ item.organizationName }}</md-table-cell>
            <md-table-cell md-label="Email" md-sort-by="email">{{ item.email }}</md-table-cell>
            <md-table-cell md-label="Anfrage" md-sort-by="createdDate">{{ item.createdDate | moment("DD.MM.YYYY") }}</md-table-cell>
            <md-table-cell md-label="Anlage" md-sort-by="testAccountCreatedDate">{{ item.testAccountCreatedDate | moment("DD.MM.YYYY") }}</md-table-cell>
            <md-table-cell md-label="Ende" md-sort-by="testAccountExpirationDate">{{ item.testAccountExpirationDate | moment("DD.MM.YYYY") }}</md-table-cell>
            <md-table-cell md-label="verbleibende Tage" md-sort-by="remainingTestDays" v-html="remainingDays(item)"></md-table-cell>
            <md-table-cell md-label="Test-Lizenz angelegt" md-sort-by="testAccountCreated">{{ booleanText(item.testAccountCreated) }}</md-table-cell>

            <md-tooltip style="font-size: medium;" md-direction="top">
              <span>Email</span>: <b>{{item.email}}</b><br><br>
              <b>Einwilligungen:</b><br>
              <span>Datenspeicherung während Testzeit</span>: <b>{{booleanText(item.allowDataStorageDuringTestphase)}}</b><br>
              <span>Kontaktaufnahme während Testzeit</span>: <b>{{booleanText(item.allowContactDuringTestphase)}}</b><br>
              <span>Datenspeicherung nach Testzeit (1 Monat)</span>: <b>{{booleanText(item.allowDataStorageEnhancedDuration)}}</b><br>
              <span>Kontaktaufnahme nach Testzeit (1 Monat)</span>: <b>{{booleanText(item.allowContactEnhancedDuration)}}</b>
            </md-tooltip>
          </md-table-row>

        </md-table>
      </div>
    </div>

    
    <br>
    <div class="grid-x grid-padding-x">
      <div v-if="isSage" class="cell large-3 medium-4">
        <div @click="onCreateTestAccount" class="button success expanded" :disabled="!selected || selected.testAccountCreated">
          <md-icon class="md-button-icon">person_add</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz anlegen
        </div>
      </div>
      <div v-if="isSage" class="cell large-3 medium-4">
        <div @click="onDeactivateTestAccount" class="button expanded" :disabled="!selected || !(selected && selected.testAccountCreated) || selected.remainingTestDays < 0">
          <md-icon class="md-button-icon">person_add_disabled</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz <b>de</b>aktivieren
        </div>
      </div>
      <div v-if="isSage" class="cell large-3 medium-4">
        <div @click="onReactivateTestAccount" class="button secondary expanded" :disabled="!selected || !(selected && selected.testAccountCreated && selected.remainingTestDays < 0)">
          <md-icon class="md-button-icon">person_add</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz <b>re</b>aktivieren
        </div>
      </div>
      <div v-if="isSage" class="cell large-3 medium-4">
        <div @click="onDeleteTestLicenseInquiry" class="button alert expanded" :disabled="!selected">
          <md-icon class="md-button-icon">delete</md-icon>&nbsp;&nbsp;&nbsp;Anfrage löschen
        </div>
      </div>
    </div>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showCreateTestAccountDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><md-icon style="color: black;">person_add</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz anlegen - {{selected.email}}</md-dialog-title>
        <div class="cell">
          <md-content>Soll ein Test-Lizenz für den Interessenten <b>{{selected.email}}</b> wirklich angelegt werden?</md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button v-if="isSage" @click="createTestAccount" class="button success" :disabled="sending">
            <md-icon class="md-button-icon">person_add</md-icon>&nbsp;&nbsp;&nbsp;Ja, Test-Lizenz jetzt anlegen
          </button>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="showCreateTestAccountDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showCreateTestAccountDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeactivateTestAccountDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><md-icon style="color: black;">person_add_disabled</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz deaktivieren - {{selected.email}}</md-dialog-title>
        <div class="cell">
          <md-content>Soll der Test-Lizenz für den Interessenten <b>{{selected.email}}</b> wirklich deaktiviert werden?</md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button v-if="isSage" @click="deactivateTestAccount" class="button success" :disabled="sending">
            <md-icon class="md-button-icon">person_add</md-icon>&nbsp;&nbsp;&nbsp;Ja, Test-Lizenz jetzt deaktivieren
          </button>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="showDeactivateTestAccountDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeactivateTestAccountDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showReactivateTestAccountDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><md-icon style="color: black;">delete</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz reaktivieren - {{selected.email}}</md-dialog-title>
        <div class="cell">
          <md-content>Soll der Test-Lizenz für den Interessenten <b>{{selected.email}}</b> wirklich reaktiviert werden?</md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button v-if="isSage" @click="reactivateTestAccount" class="button success" :disabled="sending">
            <md-icon class="md-button-icon">delete</md-icon>&nbsp;&nbsp;&nbsp;Ja, Test-Lizenz jetzt reaktivieren
          </button>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="showReactivateTestAccountDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showReactivateTestAccountDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>

    <md-dialog :md-click-outside-to-close="false" :md-active.sync="showDeleteTestLicenseInquiryDialog">
      <div v-if="selected" class="grid-x grid-padding-x grid-padding-y">
        <md-dialog-title><md-icon style="color: black;">delete</md-icon>&nbsp;&nbsp;&nbsp;Test-Lizenz-Anfrage löschen - {{selected.email}}</md-dialog-title>
        <div class="cell">
          <md-content>Soll die Test-Lizenz-Anfrage für den Interessenten <b>{{selected.email}}</b> wirklich gelöscht werden?</md-content>
          <br>
          <md-progress-bar md-mode="indeterminate" v-if="sending"/>
        </div>
        <div class="cell text-center">
          <button v-if="isSage" @click="deleteTestLicenseInquiry" class="button success" :disabled="sending">
            <md-icon class="md-button-icon">delete</md-icon>&nbsp;&nbsp;&nbsp;Ja, Test-Lizenz-Anfrage jetzt löschen
          </button>
          <button class="button alert" style="margin-left: 1rem;"
                  @click="showDeleteTestLicenseInquiryDialog = false"
                  type="button"
                  :disabled="sending">
            <i class="fi-x"></i>&nbsp;&nbsp;&nbsp;Nein, lieber nicht
          </button>
        </div>
        <button class="close-button" type="button" @click="showDeleteTestLicenseInquiryDialog = false">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </md-dialog>
  </div>

</template>

<script>
  import Spinner from 'vue-simple-spinner'

  import OrganizationService from '../services/OrganizationService';
  import HttpErrorHandler from '../services/HttpErrorHandler';
  import jQuery from "jquery";

  const toLower = text => {
    return text.toString().toLowerCase();
  };

  const searchByEmail = (items, term) => {
    if (term) {
      return items.filter(item => toLower(item.email).includes(toLower(term)));
    }

    return items;
  };

  export default {
    name: 'TestLicenseInquiries',
    components: {
      'vue-simple-spinner': Spinner
    },

    mounted() {
      this.reloadTestAccountInquiries();

      setTimeout(() => {
        jQuery('.md-table-content.md-scrollbar.md-theme-default').removeAttr('style');
      }, 500);
    },

    data() {
      return {
        sending: false,
        testLicenseInquiries: [],
        searched: [],
        search: null,
        selected: null,
        success: false,
        successMsg: '',
        showCreateTestAccountDialog: false,
        showDeactivateTestAccountDialog: false,
        showReactivateTestAccountDialog: false,
        showDeleteTestLicenseInquiryDialog: false,
      }
    },

    methods: {

      booleanText(value) {
        return value ? 'ja' : 'nein';
      },

      remainingDays(item) {
        if (item.remainingTestDays < 0 && !item.testAccountCreated) {
          return '<i style="color: dodgerblue">nicht angelegt</i>';
        } else
        if (item.remainingTestDays < 0 && item.testAccountCreated) {
          return '<i style="color: orangered">inaktiv</i>';
        } else {
          return String(item.remainingTestDays);
        }
      },

      reloadTestAccountInquiries() {
        let maxTries = process.env.VUE_APP_RELOAD_MAX_TRIES;
        const reloadIntervalId = setInterval(() => {
          if (this.dataAvailable) {
            if (this.isSage) {
              this.getAllTestAccountInquiries();
            }
            clearInterval(reloadIntervalId);
          } else {
            this.sending = true;
            maxTries--;
            if (maxTries <= 0) {
              this.sending = false;
              clearInterval(reloadIntervalId);
              HttpErrorHandler.handleError(null, this, 'Fehler beim Laden von Benutzer / Organisation / Labels');
            }
          }
        }, 250);
      },

      getAllTestAccountInquiries() {
        this.sending = true;
        OrganizationService.getAllTestAccountInquiries()
          .then(response => {
            this.testLicenseInquiries = response.data;
            this.searchOnTable();
            this.sending = false;
          })
          .catch(e => {
            HttpErrorHandler.handleError(e, this, 'Fehler beim Laden aller Lizenz-Anfragen');
            this.sending = false;
          })
      },

      searchOnTable() {
        this.searched = searchByEmail(this.testLicenseInquiries, this.search);
      },

      onCreateTestAccount() {
        if (this.selected) {
          this.showCreateTestAccountDialog = true;
        }
      },

      createTestAccount() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          OrganizationService.createTestAccount(this.selected.email)
            .then(response => {
              this.testLicenseInquiries = response.data;
              this.showCreateTestAccountDialog = false;
              this.reloadTestAccountInquiries();
              this.sending = false;
              this.$store.commit('successMsg', 'Ein Test-Lizenz für die Email &nbsp;<b>' + this.selected.email + '</b>&nbsp;wurde erfolgreich angelegt!');
              this.success = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Anlegen des Test-Lizenzs für Interessent ' + this.selected.email + '</b>');
              this.sending = false;
            })
        }
      },

      onDeactivateTestAccount() {
        if (this.selected) {
          this.showDeactivateTestAccountDialog = true;
        }
      },

      deactivateTestAccount() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          OrganizationService.deactivateTestAccount(this.selected.id)
            .then(response => {
              this.testLicenseInquiries = response.data;
              this.showDeactivateTestAccountDialog = false;
              this.reloadTestAccountInquiries();
              this.sending = false;
              this.$store.commit('successMsg', 'Der Test-Lizenz für die Email &nbsp;<b>' + this.selected.email + '</b>&nbsp;wurde erfolgreich deaktiviert!');
              this.success = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Deaktivieren des Test-Lizenzs des Test-Lizenz für die Email ' + this.selected.email + '</b>');
              this.sending = false;
            })
        }
      },

      onReactivateTestAccount() {
        if (this.selected) {
          this.showReactivateTestAccountDialog = true;
        }
      },

      reactivateTestAccount() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          OrganizationService.reactivateTestAccount(this.selected.id)
            .then(response => {
              this.testLicenseInquiries = response.data;
              this.showReactivateTestAccountDialog = false;
              this.reloadTestAccountInquiries();
              this.sending = false;
              this.$store.commit('successMsg', 'Der Test-Lizenz für die Email &nbsp;<b>' + this.selected.email + '</b>&nbsp;wurde erfolgreich reaktiviert!');
              this.success = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Reaktivieren des Test-Lizenzs des Test-Lizenz für die Email ' + this.selected.email + '</b>');
              this.sending = false;
            })
        }
      },

      onDeleteTestLicenseInquiry() {
        if (this.selected) {
          this.showDeleteTestLicenseInquiryDialog = true;
        }
      },

      deleteTestLicenseInquiry() {
        if (this.selected) {
          this.success = false;
          this.sending = true;
          OrganizationService.deleteTestAccountInquiry(this.selected.id)
            .then(response => {
              this.testLicenseInquiries = response.data;
              this.showDeleteTestLicenseInquiryDialog = false;
              this.reloadTestAccountInquiries();
              this.sending = false;
              this.$store.commit('successMsg', 'Die Test-Lizenz-Anfrage für die Email &nbsp;<b>' + this.selected.email + '</b>&nbsp;wurde erfolgreich gelöscht.');
              this.success = true;
            })
            .catch(e => {
              HttpErrorHandler.handleError(e, this, 'Fehler beim Löschen der Test-Lizenz-Anfrage für Interessent ' + this.selected.email + '</b>');
              this.sending = false;
            })
        }

      },

      getClass: ({id}) => ({
        'md-primary': id === id
      }),


      resetStates() {
        this.success = false;
      },

      onSelect(item) {
        if (item) {
          this.selected = JSON.parse(JSON.stringify(item));
        } else {
          this.selected = null;
        }
      },
    },

    computed: {

      tableStyle() {
        return this.$store.getters.sage ? 'min-height: 720px; height: calc(100vh - 345px); overflow-x: hidden' : 'min-height: 720px; height: calc(100vh - 349px); overflow-x: hidden'
      },

      dataAvailable() {
        return this.isAuthenticated;
      },

      isAuthenticated() {
        return this.$store.getters.authenticated;
      },

      isAdmin() {
        return this.$store.getters.admin;
      },

      isSage() {
        return this.$store.getters.sage;
      },

      organization() {
        return this.$store.getters.organization;
      },

      labels() {
        return this.$store.getters.labels
      },
    }
  }
</script>

<style lang="scss" scoped>
  i {
    color: black;
  }

  .md-table.md-theme-default .md-table-row.md-selected-single.md-primary {
    background: darkseagreen;
  }

  .md-button-icon {
    font-size: 20px!important; height: 15px; color: black!important;
  }

  .md-tooltip {
    font-size: medium;
    height: auto;
    white-space: normal;
  }
</style>
